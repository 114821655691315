<template>
  <div id="app" :style="{ 'border: 3px solid red': isLoggedIn }">
    <Header/>

    <div class="wrapper d-flex flex-column">
      <RouterView></RouterView>
      <cookie-law theme="dark-lime" :buttonText="$t('cookie_button')">
        <div slot="message">
          {{$t("cookie")}}
        </div>
      </cookie-law>
      <Feedback></Feedback>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import CookieLaw from "vue-cookie-law";
import Feedback from "@/components/Feedback";
import { isLoggedIn } from "@/tools";

export default {
  name: "App.vue",

  components: {
    Header,
    CookieLaw,
    Feedback,
  },

  data() {
    return {
    };
  },

  created() {
    let locale = window.location.search.substring(1).split("&")[0].split("=")[1];
    if (locale) {
      this.$i18n.locale = locale;
    }
  },

  methods: {
    isLoggedIn: isLoggedIn(),
  },
};
</script>

<style scoped lang="scss">

#app {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100%;
}

.wrapper {
  flex: 1;
}

.locked{
  filter: blur(20px);
}

.lock-msg{
  position: absolute;
  top: 50%;
  left: 35%;
  z-index: 3000000;
}

</style>
