// import store from "@state/store";

export default [
  {
    path: "/",
    name: "home",
    component: () => lazyLoadView(import("@/views/home.vue")),
    meta: {
      breadcrumb: {
        de: "Start",
        en: "home",
        parent: "",
      },
    },
  },
  {
    path: "/map-test",
    name: "map-test",
    component: () => lazyLoadView(import("@/views/MapTest")),
  },
  {
    path: "/method-intro",
    name: "methodIntro",
    component: () => lazyLoadView(import("@/views/MethodIntro")),
    meta: {
      breadcrumb: {
        de: "Technologischer Hebel",
        en: "Technological Lever",
        parent: "home",
      },
    },
  },
  {
    path: "/roadmaps/:id?",
    name: "roadmaps",
    component: () => lazyLoadView(import("@/views/RoadmapMap")),
    meta: {
      breadcrumb: {
        de: "UNFCCC Roadmaps",
        en: "Roadmaps",
        parent: "home",
      },
    },
  },
  {
    path: "/method/:id",
    name: "method",
    component: () => lazyLoadView(import("@/views/MethodMap")),
    meta: {
      breadcrumb: {
        de: "Methode",
        en: "method",
        parent: "methodIntro",
      },
    },
  },
  {
    path: "/pathway-intro",
    name: "scenarioIntro",
    component: () => lazyLoadView(import("@/views/ScenarioIntro")),
    meta: {
      breadcrumb: {
        de: "Entscheidungsunterstützender Hebel",
        en: "Decision Support Lever",
        parent: "home",
      },
    },
  },
  {
    path: "/contribution/:id/:page?",
    name: "contribution",
    component: () => lazyLoadView(import("@/views/Contribution")),
    meta: {
      breadcrumb: {
        de: "Beitrag",
        en: "Contribution",
        parent: "scenarioIntro",
      },
    },
  },
  {
    path: "/pathway/:id",
    name: "scenario",
    component: () => lazyLoadView(import("@/views/Story")),
    meta: {
      breadcrumb: {
        de: "Pfad",
        en: "Scenario",
        parent: "scenarioIntro",
      },
    },
  },
  {
    path: "/pathway/:id/more-info",
    name: "moreInfo",
    component: () => lazyLoadView(import("@/views/MoreInfo")),
    meta: {
      breadcrumb: {
        de: "Mehr Info",
        en: "more information",
        parent: "scenario",
      },
    },
  },
  {
    path: "/partners",
    name: "partners",
    component: () => lazyLoadView(import("@/views/PartnersMap")),
    meta: {
      breadcrumb: {
        de: "Partnerzentren",
        en: "Participating Centers",
        parent: "home",
      },
    },
  },
  {
    path: "/glossary",
    name: "glossary",
    component: () => lazyLoadView(import("@/views/Glossary")),
    meta: {
      scrollToTop: true,
      breadcrumb: {
        de: "Glossar",
        en: "glossary",
        parent: "home",
      },
    },
  },
  {
    path: "/about/atlas",
    name: "aboutAtlas",
    component: () => lazyLoadView(import("@/views/AboutAtlas")),
    meta: {
      breadcrumb: {
        de: "Über den Atlas",
        en: "about the atlas",
        parent: "home",
      },
    },
  },
  {
    path: "/about/cluster",
    name: "aboutCluster",
    component: () => lazyLoadView(import("@/views/AboutCluster")),
    meta: {
      breadcrumb: {
        de: "Über den Cluster",
        en: "about the cluster",
        parent: "home",
      },
    },
  },
  {
    path: "/404",
    name: "404",
    component: require("@/views/core/404.vue").default,
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: "*",
    redirect: "404",
  },
];

// // Lazy-loads view components, but with better UX. A loading view
// // will be used if the component takes a while to load, falling
// // back to a timeout view in case the page fails to load. You can
// // use this component to lazy-load a route with:
// //
// // component: () => lazyLoadView(import('@views/my-view'))
// //
// // NOTE: Components loaded with this strategy DO NOT have access
// // to in-component guards, such as beforeRouteEnter,
// // beforeRouteUpdate, and beforeRouteLeave. You must either use
// // route-level guards instead or lazy-load the component directly:
// //
// // component: () => import('@views/my-view')
// //
function lazyLoadView(AsyncView) {
  const AsyncHandler = () => ({
    component: AsyncView,
    // A component to use while the component is loading.
    loading: require("@/views/core/loading.vue").default,
    // Delay before showing the loading component.
    // Default: 200 (milliseconds).
    delay: 400,
    // A fallback component in case the timeout is exceeded
    // when loading the component.
    error: require("@/views/core/timeout.vue").default,
    // Time before giving up trying to load the component.
    // Default: Infinity (milliseconds).
    timeout: 10000,
  });

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      // Transparently pass any props or children
      // to the view component.
      return h(AsyncHandler, data, children);
    },
  });
}
